import React, { Component, useEffect } from "react"

// Components
import Layout from "../components/Layout"
import SEO from "../components/seo"
import Navbar from "../components/Navbar"

import TwitterAvatarUrl from "../images/transferable-twitter-avatar.jpg"

// Hooks
import { useWindowSize } from "../hooks/useWindowSize"

// Styles
import "./resources.styles.scss"

/*
const ResourcesPage = () => {

  const createTwitterFeed = () => {
    const anchor = document.createElement("a")
    anchor.setAttribute("class", "twitter-timeline")
    anchor.setAttribute("data-theme", "dark")
    //anchor.setAttribute("data-tweet-limit", "30")
    anchor.setAttribute("data-chrome", "noheader nofooter noborders")
    anchor.setAttribute(
      "href",
      "https://twitter.com/transfer_edu/timelines/1208134490524147713?ref_src=twsrc%5Etfw"
    )

    document.getElementsByClassName("twitter-embed")[0].appendChild(anchor)

    const script = document.createElement("script")
    script.setAttribute("src", "https://platform.twitter.com/widgets.js")

    document.getElementsByClassName("twitter-embed")[0].appendChild(script)
  };

  const addStyles = () => {
    let iframeHead;

    const twitterStylesTimer = setInterval(function() {
      const iframe = document.querySelector('iframe');
      const iframeDocument = iframe.contentDocument || iframe.contentWindow;
      iframeHead = iframeDocument.head || iframeDocument.document.head;

      const applyDemStyles = (element, stylesObj) => {
        Object.entries(stylesObj).forEach(s => {
          element.style.s[0] = s[1];
        });
      };

      const applyStylesToElement = (className, styles) => {
        const el = iframeDocument.getElementsByClassName(className)[0];

        applyDemStyles(el, styles);
      };

      const applyStylesToAllElement = (className, styles) => {
        const elList = iframeDocument.getElementsByClassName(className);
        const elListArr = Array.from(elList);
        
        elListArr.forEach(el => applyDemStyles(el, styles));
      };

      if (!frameHead.hasChildNodes()) {
        return;
      }

      applyStylesToElement('timeline-Widget', {
        maxWidth: '1400px',
        margin: '0 auto',
      });

      applyStylesToElement('timeline-TweetList', {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#f8f8f8',
        color: '#001533',
        fontWeight: 'bold',
        margin: '0 auto',
      });

      applyStylesToAllElement('timeline-TweetList-tweet', {
        maxWidth: ''
      });

      clearInterval(twitterStylesTimer);
    }, 1000);
  };

  useEffect(() => {}, [])

  return (
    <Layout currentPath="/resources">
      <SEO title="Public Benefit" />
      <div className="resources-wrapper">
        <Navbar />
        <div className="public-benefit">
          <header className="public-benefit__header">
            <h4 className="header-text">
              We're sharing GIFs and useful resources for students on our
              Twitter pages.
            </h4>
            <p className="header-text follow-us">
              Check them out below and follow us:
            </p>
            <a
              href="https://twitter.com/transfer_edu"
              className="transferable-twitter-info"
            >
              <img
                className="transferable-twitter-info__avatar"
                src={TwitterAvatarUrl}
                alt="Transferable's twitter avatar"
              />
              <div className="transferable-twitter-info__text">
                <h6 className="transferable-twitter-info__title">
                  {" "}
                  Transferable
                </h6>
                <span className="transferable-twitter-info__handle">
                  @transfer_edu
                </span>
              </div>
            </a>
          </header>
          <main className="public-benefit__main">
            <div className="twitter-container" style={{ width: "100%" }}>
              <div
                className="twitter-embed"
                style={{
                  overflow: "visible",
                  marginRight: "0",
                }}
              >
                }
              </div>
            </div>
          </main>
        </div>
      </div>
    </Layout>
  )
}
*/

class ResourcesPage extends Component {

  componentDidMount() {
    const anchor = document.createElement("a")
    anchor.setAttribute("class", "twitter-timeline")
    anchor.setAttribute("data-theme", "dark")
    //anchor.setAttribute("data-tweet-limit", "30")
    anchor.setAttribute("data-chrome", "noheader nofooter noborders")
    anchor.setAttribute(
      "href",
      "https://twitter.com/transfer_edu/timelines/1208134490524147713?ref_src=twsrc%5Etfw"
    )

    document.getElementsByClassName("twitter-embed")[0].appendChild(anchor)

    const script = document.createElement("script")
    script.setAttribute("src", "https://platform.twitter.com/widgets.js")

    document.getElementsByClassName("twitter-embed")[0].appendChild(script)
    //Add twitter widget styling
    let iframeHead

    const twitterStylesTimer = setInterval(function() {
      const iframe = document.querySelector("iframe");
      iframeHead =
        iframe.contentDocument.head || iframe.contentWindow.document.head

      if (!iframeHead.hasChildNodes()) {
        console.log("nothing yet")
      } else if (iframeHead.hasChildNodes()) {
        const iframeDocument = iframe.contentDocument || iframe.contentWindow
        const iframeBody =
          iframe.contentDocument.body || iframe.contentWindow.document.body

        const timelineWidget = getTimelineWidget(iframeDocument)
        timelineWidget.style.maxWidth = "1400px"
        timelineWidget.style.margin = "0 auto"

        const tweetList = getTweetList(iframeDocument)
        tweetList.style.display = "flex"
        tweetList.style.flexWrap = "wrap"
        tweetList.style.justifyContent = "center"
        tweetList.style.alignItems = "start"
        tweetList.style.backgroundColor = "#f8f8f8"
        tweetList.style.color = "#001533"
        tweetList.style.fontWeight = "bold"
        tweetList.style.margin = "0 auto"


        const tweetWrappers = getTweetWrappers(iframeDocument)
        tweetWrappers.forEach(tweet => {
          tweet.style.maxWidth = "275px"
          tweet.style.width = "100%"
          tweet.style.cursor = "pointer"
          tweet.style.borderRadius = "5px"
          tweet.style.padding = "30px 20px"
          tweet.style.boxShadow = "0 1px 5px rgba(0, 0, 0, 0.15)"
          tweet.style.transition = "box-shadow .3s ease-in-out"
          tweet.style.margin = "20px 15px"
          tweet.style.backgroundColor = "#fff"

          tweet.addEventListener("mouseover", () => {
            //tweet.style.backgroundColor = "lightgrey";
            tweet.style.boxShadow = "0 5px 15px rgba(0,0,0,.3)"
          })

          tweet.addEventListener("mouseleave", () => {
            //tweet.style.backgroundColor = "#fff";
            tweet.style.boxShadow = "0 1px 2px rgba(0, 0, 0, 0.15)"
          })
        })

        const tweets = getTweets(iframeDocument)
        tweets.forEach(tweet => {
          tweet.classList.remove("timeline-Tweet")
          tweet.style.width = "100%"
          tweet.style.padding = "0"
        })

        const txt = getTweetText(iframeDocument)
        txt.forEach(t => {
          t.style.maxWidth = "90%"
          t.style.fontSize = "16px"
          t.style.lineHeight = "125%"
          t.style.marginLeft = "0"
          t.style.fontWeight = "500"
        })

        const imgs = getImgWrapper(iframeDocument)
        imgs.forEach(img => {
          img.style.marginLeft = "0"
        })

        const authorElements = getTweetAuthor(iframeDocument)
        authorElements.forEach(ae => {
          ae.style.display = "none"
        })

        const links = getLinks(iframeDocument)
        links.forEach(link => {
          link.style.display = "block"
          link.style.margin = "5px 0 10px"
        })

        const loadMore = getLoadMore(iframeDocument);
        loadMore.style.display = "none"

        clearInterval(twitterStylesTimer)
      }
    }, 1000)

    const getTimelineWidget = iframeDocument => {
      const widget = iframeDocument.getElementsByClassName('timeline-Widget');
      return widget[0];
    };

    const getTweetList = iframeDocument =>
      iframeDocument.getElementsByClassName("timeline-TweetList")[0]

    const getTweetWrappers = iframeDocument => {
      const tweets = iframeDocument.getElementsByClassName(
        "timeline-TweetList-tweet"
      )
      return Array.from(tweets)
    }

    const getTweets = iframeDocument => {
      const tweets = iframeDocument.getElementsByClassName("timeline-Tweet")

      return Array.from(tweets)
    }

    const getImgWrapper = iframeDocument => {
      const img = iframeDocument.getElementsByClassName("timeline-Tweet-media")

      return Array.from(img)
    }

    const getTweetText = iframeDocument => {
      const txt = iframeDocument.getElementsByClassName("timeline-Tweet-text")

      return Array.from(txt)
    }

    const getTweetAuthor = iframeDocument => {
      const authorElement = iframeDocument.getElementsByClassName(
        "timeline-Tweet-author"
      )

      return Array.from(authorElement)
    }

    const getLinks = iframeDocument => {
      const links = iframeDocument.getElementsByClassName("link")

      return Array.from(links)
    }

    const getLoadMore = iframeDocument => {
      const loadMore = iframeDocument.getElementsByClassName("timeline-LoadMore");

      return loadMore[0];
    };

    /*
    var twitterStylesTimer = window.setInterval(function() {
      $iframeHead = $("iframe#twitter-widget-0")
        .contents()
        .find("head")

      if (!$("#twitter-widget-styles", $iframeHead).length) {
        //If our stylesheet does not exist tey to place it
        $iframeHead.append(
          '<link rel="stylesheet" href="/stylesheets/twitter-widget.css" id="twitter-widget-styles">'
        )
      } else if ($("#twitter-widget-styles", $iframeHead).length) {
        //If stylesheet exists then quit timer
        clearInterval(twitterStylesTimer)
      }
    }, 200)
*/
  }

  render() {
    return (
      <Layout currentPath="/resources">
        <SEO title="Resources" />
        <div className="resources-wrapper">
          <Navbar />
          <div className="public-benefit">
            <header className="public-benefit__header">
              <h4 className="header-text">
                We're sharing GIFs and useful resources for students on our
                Twitter pages.
              </h4>
              <p className="header-text follow-us">
                Check them out below and follow us:
              </p>
              <a
                href="https://twitter.com/transfer_edu"
                className="transferable-twitter-info"
              >
                <img
                  className="transferable-twitter-info__avatar"
                  src={TwitterAvatarUrl}
                  alt="Transferable's twitter avatar"
                />
                <div className="transferable-twitter-info__text">
                  <h6 className="transferable-twitter-info__title">
                    {" "}
                    Transferable
                  </h6>
                  <span className="transferable-twitter-info__handle">
                    @transfer_edu
                  </span>
                </div>
              </a>
            </header>
            <main className="public-benefit__main">
              <div className="twitter-container" style={{ width: "100%" }}>
                <div
                  className="twitter-embed"
                  style={{
                    overflow: "visible",
                    marginRight: "0",
                  }}
                >
                  {/* Twitter feed goes here */}
                </div>
              </div>
            </main>
          </div>
        </div>
      </Layout>
    )
  }
}

export default ResourcesPage
